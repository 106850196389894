<template>
  <base-button
    @click="share()"
    type="gradient-linkedin"
    icon="fa fa-linkedin"
    iconOnly
    rounded
  ></base-button>
</template>

<script>
export default {
  methods: {
    share() {
      const href =
        "https://www.linkedin.com/sharing/share-offsite/?url=" +
        window.location.href;
      window.open(href, "_blank");
    }
  }
};
</script>

<style></style>
