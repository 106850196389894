<template>
  <base-button
    @click="share()"
    type="gradient-facebook"
    icon="fa fa-facebook"
    iconOnly
    rounded
  ></base-button>
</template>

<script>
export default {
  methods: {
    share() {
      const href =
        "https://facebook.com/sharer/sharer.php?u=" + window.location.href;
      window.open(href, "_blank");
    }
  }
};
</script>

<style></style>
