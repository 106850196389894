<template>
  <div class="wrapper">
    <section class="section mt-5">
      <div v-if="!post" class="text-center">
        <spinner class="text-primary spinner-border-lg mb-5"></spinner>
      </div>
      <div v-else class="container">
        <div v-if="post" class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h2 class="title text-primary">{{ post.title }}</h2>
            <h6 class="category text-gray">
              {{ formatDate(post.created_at) }}
              <span class="ml-2 mr-2 dot">·</span>
              {{ post.read_time }} read
            </h6>
            <div>
              <img
                class="mb-4 mt-4"
                width="100%"
                :src="post.feature_image"
                alt=""
              />
            </div>
            <div v-html="post.content"></div>
            <div class="mt-5 mb-3">
              <share-facebook></share-facebook>
              <share-twitter></share-twitter>
              <share-linkedin></share-linkedin>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div v-if="!similar_posts" class="text-center">
        <div class="row">
          <div class="mx-auto col-sm-4" v-for="(p, index) in 3" :key="index">
            <spinner class="text-primary mt-5" type="grow"></spinner>
          </div>
        </div>
      </div>
      <div v-else>
        <BlogSuggestion :posts="similar_posts" />
      </div>
    </section>
  </div>
</template>
<script>
const Spinner = () =>
  import(/* webpackChunkName: "Spinner"*/ "@/components/Spinner");
import Moment from "moment";
const BlogSuggestion = () =>
  import(
    /* webpackChunkName: "BlogSuggestion"*/ "../components/BlogSuggestion"
  );
import ShareFacebook from "@/components/ShareButtons/Facebook";
import ShareTwitter from "@/components/ShareButtons/Twitter";
import ShareLinkedin from "@/components/ShareButtons/Linkedin";
export default {
  bodyClass: "blog-post",
  components: {
    BlogSuggestion,
    Spinner,
    ShareFacebook,
    ShareTwitter,
    ShareLinkedin
  },
  data: function() {
    return {
      post: { title: null, content: null },
      similar_posts: null
    };
  },
  metaInfo() {
    return {
      title: this.post.title,
      meta: [
        {
          name: "description",
          content: this.post.content ? this.post.content.substr(0, 150) : ""
        }
      ]
    };
  },
  mounted() {
    this.getBlogPostDetail();
    this.getOtherBlogPosts();
  },
  methods: {
    getBlogPostDetail() {
      this.loading = true;
      this.$axios
        .get("blog/post/" + this.$route.params.id + "/")
        .then(res => {
          if (this.$route.params.slug == res.data.slug) {
            this.loading = false;
            this.post = res.data;
            this.getOtherBlogPosts();
          } else {
            this.$router.push("/404");
          }
        })
        .catch(err => {
          if (err.response) {
            this.$router.push("/404");
          }
        });
    },
    getOtherBlogPosts() {
      this.$axios
        .post("blog/similar-post/", { id: this.$route.params.id })
        .then(res => {
          this.similar_posts = res.data;
        });
    },
    formatDate(date) {
      return Moment(date).format("MMM D[,] YYYY");
    }
  },
  watch: {
    $route() {
      this.getOtherBlogPosts();
      this.getBlogPostDetail();
    }
  }
};
</script>

<style>
.dot {
  font-size: 14px;
  color: rgba(117, 117, 117, 1);
  line-height: 20px;
  font-weight: 400;
}
/* img {
  width: 100% !important;
  height: auto !important;
} */
.divider {
  border: 1px solid rgb(219, 225, 219);
}
</style>
